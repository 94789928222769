import Api from '@/services/Index';

const getAll = (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT
    return Api.get('/proficiency/exam/scores', config)
}

const update = (formData, Id) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT
    return Api.put('/placement/exam/scores/'+Id, formData)
}

const remove = (Id) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT
    return Api.delete('/placement/exam/scores/'+Id)
}

export default {
    getAll,
    update,
    remove
}
