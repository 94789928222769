<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('proficiency_exam_scores')"
                        :isColumns="true"
                        @filter-div-status="datatable.filterStatus=$event"
                >
                    <template v-slot:columns>
                        <div class="mb-1" v-for="(column,key) in datatable.columns">
                            <b-form-checkbox
                                :id="'checkbox-'+key"
                                :name="'checkbox-'+key"
                                :value="false"
                                :unchecked-value="true"
                                v-model="column.hidden"
                                v-if="column.field!='buttons'">
                                {{ column.label }}
                            </b-form-checkbox>
                        </div>
                    </template>
                </Header>
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('proficiency_exam_scores')"
                              :isColumns="true"
                              @filter-div-status="datatable.filterStatus=$event">
                    <template v-slot:columns>
                        <div class="mb-1" v-for="(column,key) in datatable.columns">
                            <b-form-checkbox
                                :id="'checkbox-'+key"
                                :name="'checkbox-'+key"
                                :value="false"
                                :unchecked-value="true"
                                v-model="column.hidden"
                                v-if="column.field!='buttons'"
                            >
                                {{ column.label }}
                            </b-form-checkbox>
                        </div>
                    </template>
                </HeaderMobile>
            </template>
            <datatable-filter
                v-show="datatable.filterStatus"
                @filter="filter"
                @filterClear="filterClear">
                <b-row>
                    <b-col cols="12" md="4">
                        <b-form-group :label="$t('academic_year')">
                            <academic-years-selectbox v-model="datatable.queryParams.filter.academic_year" />
                        </b-form-group>
                    </b-col>
                </b-row>
            </datatable-filter>
            <datatable :isLoading.sync="datatable.isLoading"
                       :columns="datatable.columns"
                       :rows="datatable.rows"
                       :total="datatable.total"
                       :queryParams="datatable.queryParams"
                       @on-page-change="onPageChange"
                       @on-sort-change="onSortChange"
                       @on-per-page-change="onPerPageChange"
                       v-show="datatable.showTable"
            >
            </datatable>
            <CommonModal ref="updateModal" size="md">
                <template v-slot:CommonModalTitle>
                    <div>{{ updateTitle }}</div>
                </template>
                <template v-slot:CommonModalContent>
                    <update-form
                        :update-data="updateData"
                        :update-mode="updateMode"
                        @updated="afterUpdate"
                        v-if="updateTitle"></update-form>
                </template>
            </CommonModal>
        </app-layout>
    </div>
</template>
<script>
// Template
import AppLayout from '@/layouts/AppLayout'
import Header from '@/layouts/AppLayout/Header'
import HeaderMobile from '@/layouts/AppLayout/HeaderMobile'

// Components
import DatatableFilter from '@/components/datatable/DatatableFilter'
import Datatable from '@/components/datatable/Datatable'
import CommonModal from '@/components/elements/CommonModal'
import AcademicYearsSelectbox from "@/components/interactive-fields/AcademicYearsSelectbox"

// Pages
import UpdateForm from './UpdateForm'

// Services
import ProficiencyExamScoreService from "@/services/ProficiencyExamScoreService"

// Others
import qs from 'qs'
import Branchs from "@/modules/conservatoryApplication/data/Branchs"

export default {
    components: {
        AppLayout,
        Header,
        HeaderMobile,

        CommonModal,
        AcademicYearsSelectbox,

        UpdateForm,
        DatatableFilter,
        Datatable,
    },
    metaInfo() {
        return {
            title: this.$t('proficiency_exam_scores')
        }
    },
    data() {
        return {
            datatable: {
                isLoading: false,
                columns: [
                    {
                        label: '',
                        field: 'buttons',
                        html: true,
                        sortable: false,
                        tdClass: 'p-0 text-center w-40 align-middle',
                        buttons: [
                            {
                                text: this.$t('edit'),
                                class: 'ri-edit-box-line align-middle top-minus-1 mr-3 text-muted',
                                callback: (row) => {
                                    this.openUpdateModal(row, true)
                                },
                                permission:"placementexamscore_show"
                            },
                            {
                                text: this.$t('delete'),
                                class: 'ri-delete-bin-7-line align-middle top-minus-1 mr-3 text-muted',
                                callback: (row) => {
                                    this.deleteProficiencyExam(row)
                                },
                                permission:"placementexamscore_delete"
                            }
                        ]
                    },
                    {
                        label: this.$t('id'),
                        field: 'id',
                        hidden: true
                    },
                    {
                        label: this.$t('student_number'),
                        field: 'student_number',
                        hidden: false
                    },
                    {
                        label: this.$t('name'),
                        field: 'name',
                        hidden: false
                    },
                    {
                        label: this.$t('surname'),
                        field: 'surname',
                        hidden: false,
                    },
                    {
                        label: this.$t('academic_year'),
                        field: 'academic_year',
                        hidden: false
                    },
                    {
                        label: this.$t('program'),
                        field: 'program',
                        hidden: false
                    },
                    {
                        label: this.$t('program_code'),
                        field: 'program_code',
                        hidden: false
                    },
                    {
                        label: this.$t('date'),
                        field: 'date',
                        hidden: false
                    },
                    {
                        label: this.$t('score'),
                        field: 'score',
                        hidden: false
                    },
                ],
                rows: [],
                total: 0,
                filterStatus: true,
                showTable: false,
                queryParams: {
                    filter: {},
                    sort: 'id',
                    page: 1,
                    limit: 20
                }
            },

            updateData: {},
            updateTitle: null,
            updateMode: false,
        }
    },
    created() {
        this.filterSet();
        this.branchs = Branchs()
    },
    methods: {
        filterSet() {
            this.datatable.queryParams.filter = {

            }
        },
        filterClear() {
            this.filterSet()
            this.getRows();
        },
        filter() {
            this.datatable.queryParams.page = 1;
            this.getRows();
        },
        onPageChange(params) {
            this.datatable.queryParams.page = params.currentPage;
            this.getRows();
        },
        onPerPageChange(params) {
            this.datatable.queryParams.limit = params.currentPerPage;
            this.datatable.queryParams.page = 1;
            this.getRows();
        },
        changeColumn(key) {
            this.datatable.columns[key].hidden = this.datatable.columns[key].hidden == true ? false : true;
        },
        onSortChange(params) {
            const sortType = params[0].type == 'desc' ? '-' : '';
            this.datatable.queryParams.sort = sortType + params[0].field
            this.getRows();
        },

        getRows() {
            this.datatable.showTable = true;
            this.datatable.isLoading = true;

            const config = {
                params: {
                    ...this.datatable.queryParams
                },
                paramsSerializer: (params) => qs.stringify(params, {encode: false})
            };

            return ProficiencyExamScoreService.getAll(config).then(response => {
                this.datatable.rows = response.data.data
                this.datatable.total = response.data.pagination.total
            }).catch(e => {
                this.showErrors(e)
            }).finally(() => {
                this.datatable.isLoading = false;
            });
        },

        openUpdateModal(data, updateMode){
            this.updateMode = updateMode
            this.updateTitle = data.name+' '+data.surname
            this.updateData = data
            this.$refs.updateModal.$refs.commonModal.show()
        },

        afterUpdate(){
            this.$refs.updateModal.$refs.commonModal.hide()
            this.getRows()
        },

        deleteProficiencyExam(row){
            this.$swal.fire({
                    text: this.$t('are_you_sure_to_delete'),
                    showCancelButton: true,
                    confirmButtonText: this.$t('yes'),
                    cancelButtonText: this.$t('no')
                }).then((result) => {
                    if (result.isConfirmed) {
                        ProficiencyExamScoreService.remove(row.id).then(response => {
                            this.$toast.success(this.$t('api.'+response.data.message))
                            this.getRows()
                        }).catch(e => {
                            this.showErrors(e, null)
                        })
                    }
                })
        }

    }
}
</script>

