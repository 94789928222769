<template>
    <div>
        <div class="border rounded-sm pt-4 pl-4 pr-4">
            <ValidationObserver ref="updateFormValidate">
                <b-row>
                    <b-col cols="12">
                        <b-form-group :label="$t('score')">
                            <ValidationProvider name="score" rules="required" v-slot="{ errors }">
                                <b-form-input v-model="formData.score"></b-form-input>
                                <div class="invalid-feedback d-block" v-if="errors[0]" v-html="errors[0]"></div>
                            </ValidationProvider>
                        </b-form-group>
                    </b-col>
                </b-row>
                <div class="d-flex justify-content-center mb-3">
                    <processing-button :processing="formLoading" variant="primary" v-if="updateMode"
                                       :label="$t('save')" @click="updateForm" />
                </div>
            </ValidationObserver>
        </div>
    </div>
</template>

<script>
    // Component
    import ProcessingButton from '@/components/elements/ProcessingButton'

    // Service
    import ProficiencyExamScoreService from "@/services/ProficiencyExamScoreService"

    // Other
    import {ValidationObserver, ValidationProvider} from 'vee-validate'

    export default {
        props: {
            updateData: {
                type: Object
            },
            updateMode: {
                type: Boolean,
                default: true
            }
        },

        components: {
            ValidationObserver,
            ValidationProvider,
            ProcessingButton
        },

        data() {
            return {
                formData: {},
                formLoading: false
            }
        },

        methods: {
            async updateForm(){
                this.formLoading = true
                const isValid = await this.$refs.updateFormValidate.validate();
                if (isValid) {

                    ProficiencyExamScoreService.update(this.formData,this.formData.id).then(response => {
                        this.$toast.success(this.$t('api.'+response.data.message))
                        this.formLoading = false
                        this.$emit('updated',true)
                    }).catch(e => {
                        this.showErrors(e, this.$refs.updateFormValidate)
                    })
                } else {
                    this.formLoading = false
                }
            },

            loadData(data) {
                this.formData = data
            },

        },

        watch: {
            updateData: {
                handler(value) {
                    this.loadData(value)
                }
            }
        },

        created() {
            this.loadData(this.updateData)
        },
    }
</script>
